<template>
  <div class="loginPrompt">
    <el-dialog :visible.sync="goShow" width="340px" :before-close="close" :lock-scroll="false">
      <section class="tan_box">
        <div class="box">
          <div class="borders">
            <h2 class="login_title">一键登录</h2>

            <!-- 密码登录 -->
            <div class="message" v-show="active == 1">
              <el-form
                ref="ruleForm"
                :model="user"
                :rules="rules"
                @submit.native.prevent
              >
                <el-form-item prop="username">
                  <el-input
                    v-model.trim="user.username"
                    placeholder="用户名/手机号码/邮箱/身份证号"
                    auto-complete="off"
                    @change="getFindIphone(1,user.username)"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    v-model.trim="user.password"
                    type="password"
                    placeholder="请输入您的登录密码"
                  >
                    <!-- <template slot="append">忘记密码</template> -->
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <ImageCode
                    @changeCode="getchangeCode"
                    ref="myImageCode"
                  ></ImageCode>
                </el-form-item>
                <div class="paddB15 fonT12 justify">
                  <el-checkbox v-model="user.checked" class="fonT12"
                    >记住密码</el-checkbox
                  >
                  <router-link
                    to="/user/password"
                    class="fr style2 fonT12  lostpsa"
                    >忘记密码？</router-link
                  >
                </div>
                <!-- 登录 -->
                <el-button
                  class="w230"
                  type="primary"
                  native-type="submit"
                  @click="getLogin('ruleForm')"
                  v-loading="loading"
                  @keyup.enter="getLogin('ruleForm')"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="#FFF"
                  element-loading-text="正在登录"
                  :disabled="loading"
                  >登录/注册
                </el-button>
                <el-form-item prop="checkbox"
                              :rules="[
                  {required: true, message: '请先阅读并勾选用户服务协议和隐私政策', trigger:'change'},
                ]">
                  <el-checkbox v-model="user.checkbox" class="checked">
                    我接受
                    <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
                    和
                    <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
                  </el-checkbox>
                </el-form-item>
                <p style="margin-top:10px">未注册手机号验证后将自动注册本平台账号</p>
              </el-form>
            </div>
            <!-- 手机号登录 -->
            <div class="phone" v-show="active == 0">
              <el-form ref="ruleForms" :model="phone" :rules="phonerules" @submit.native.prevent>
                <el-form-item prop="iphone">
                  <el-input
                    v-model.trim="phone.iphone"
                    placeholder="请输入手机号"
                    @change="getFindIphone(2,phone.iphone)"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input v-model="phone.code" placeholder="请输入短信验证码">
                    <template slot="append">
                      <el-button
                        @click="sendCheckCode"
                        class="sendcode"
                        :class="{ 'disabled-style': getCodeBtnDisable }"
                        :disabled="getCodeBtnDisable"
                        >{{ btntxt }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <div class="paddB15 fonT12 justify">
                  <el-checkbox v-model="phone.checked" class="fonT12"
                    >记住手机号</el-checkbox
                  >
                </div>
                <el-button
                  class="w230"
                  type="primary"
                  v-loading="loading"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="#FFF"
                  element-loading-text="正在登录"
                  :disabled="loading"
                  native-type="submit"
                  @keyup.enter="getLogin('ruleForms')"
                  @click="getLogin('ruleForms')"
                  >登录/注册
                </el-button>
                <el-form-item prop="checkbox"
                              :rules="[
                  {required: true, message: '请先阅读并勾选用户服务协议和隐私政策', trigger:'change'},
                ]">
                  <el-checkbox v-model="phone.checkbox" class="checked">
                    我接受
                    <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
                    和
                    <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
                  </el-checkbox>
                </el-form-item>
                 <p style="margin-top:10px">未注册手机号验证后将自动注册本平台账号</p>
              </el-form>
            </div>
          </div>
          <div class="betww"></div>
        </div>
        <div class="text_C fonT12 col999 paddB10 quick betww">
          <div class="flexs left_icon" v-if="active == 1" @click="active = 0">
            <img src="../../static/login/sms.png" alt="" />
            <p>手机号登录</p>
          </div>
          <div class="flexs left_icon" v-if="active == 0" @click="active = 1">
            <img src="../../static/login/password.png" aria-hidden="true" alt="" />
            <p>密码登录</p>
          </div>

        </div>
      </section>
    </el-dialog>
  </div>
</template>
<script>
import ImageCode from "@/components/login/ImageCode";

export default {
  name: "LoginBar",
  inject: ["reload"],
  props: {
    goShow: Boolean,
  },
  data() {
    return {
      loading: false,
      active: 1,
      form: {},
      checked1: "",
      btntxt: "获取验证码",
      getCodeBtnDisable: false,
      time: 180,
      i: '<i class="el-icon-d-arrow-right"></i>',
      user: {
        username: "",
        password: "",
        checked: false,
        checkbox:false,
        imgCode: "",
        verifycode: "",
      },
      phone: {
        iphone: "",
        code: "",
        checked: false,
        checkbox:false,
        imgCode: "",
        verifycode: "",
      },
      ruleForm: {},
      rules: {
        username: [
          { required: true, message: "请输入您的用户名", trigger: "blur" },
        ],

        password: [
          { required: true, message: "请输入您的登录密码", trigger: "blur" },
        ],
      },
      phonerules: {
        iphone: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入收到的验证码", trigger: "blur" },
        ],
      },
      temp: "",
      tab: ["密码登录", "短信登录"],

    };
  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.phone.iphone)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    tabs(i) {
      this.active = i;
      this.loading = false;
    },
    getchangeCode(val) {
      this.user.verifycode = val.uuid;
      this.user.imgCode = val.code;
    },
    //手机号密码登录
    getLogin(form) {
      let that = this;
      let param = {};
      if (this.active == 1) {
        if (!this.user.checkbox) {
          this.$message.error("请先阅读并勾选用户服务协议和隐私政策");
          return;
        }
        param = {
          userName: this.user.username,
          password: this.$sm2Encrypt(this.user.password),
          code : this.user.imgCode,
          uuid:this.user.verifycode,
          loginType: "0",
          registerSite:'lz',
          touristType: "1"
        }
      } else {
        if (!this.phone.checkbox) {
          this.$message.error("请先阅读并勾选用户服务协议和隐私政策");
          return;
        }
        param = {
          userName: this.phone.iphone,
          password: this.phone.code,
          loginType: "1",
          registerSite:'lz',
          touristType: "1"
        }
      }
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let res = await this.$api.getLoginApi(param);
          if (res.data.success) {
            this.loading = false;
            let user = res.data.data;
            that.$message.success('登录成功！');
            //残疾人个人信息
            that.$store.commit("SET_USER_INFO", user);
            //存储access_token
            that.$store.commit("SET_TOKEN", user.token);
            //存储refresh_token
            that.$store.commit("SET_REFRESH_TOKEN", user.token);
            if (user.userType === "1") {
              this.$router.go(-1);
              this.reload();
            } else if (user.userType === "2") {
              if (user.memberStatus == "0") {
                that.$router.push("/enterprise/centre/infomanage/");
              }
              this.$router.push("/enterprise");
              this.reload();
              this.loading = false;
            } else {
              this.reload();
              if (user.touristType == 2) {
                this.$router.push("/companyLogin/authentication");
              } else {
                this.$router.push("/userLogin/authentication");
              }
            }
          }else{
            this.loading = false;
            that.$message.error(res.data.msg);
          }
        }else{
          this.loading = false;
        }
      })
    },
    sendCheckCode() {
      let iphone = /^1[3456789]\d{9}$/;
      let codeAlert = localStorage.getItem('codeAlert');
      if (iphone.test(this.phone.iphone)) {
        if (this.$route.query.id == 'geren') {
          if (codeAlert) {
            this.sendMsg();
          } else {
            this.isHide = true;
            return
          }
        } else {
          this.sendMsg();
        }

      } else {
        this.$message.error("手机号码不正确");
      }
    },
    // 单独领出来的短信发送接口
    sendMsg() {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/phoneVerification/" +
              this.phone.iphone
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
      this.handleClose();
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    //验证手机号码是否注册
    getFindIphone(type,username){
      this.$api.getfindIphoneApi({iphone:username}).then(res =>{
        if (res.data.code == 8112){
          if(type == 1){
            this.user.checkbox = true;
          }else{
            this.phone.checkbox = true;
          }
        }else{
          if(type == 1){
            this.user.checkbox = false;
          }else{
            this.phone.checkbox = false
          }
        }
      })
    },
  },
  components: {
    ImageCode,
  },
  created() {},
  mounted() {
    let checked = localStorage.getItem("checked");
    if (checked) {
      this.user.checked = true;
      this.user.username = localStorage.getItem("username");
      this.user.password = localStorage.getItem("password");
      this.phone.iphone = localStorage.getItem("iphone");
    }
  },
};
</script>

<style lang="less" scoped>
//修改el-dialog
/deep/.el-dialog {
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: -15px;
      right: -100px;
      width: 30px;
      height: 30px;
      line-height: 17px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      i {
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
section {
  min-width: 430px;
  width: 30%;
  // height: 500px;
  background: #ffffff;
  box-shadow: 0px 10px 56px 14px rgba(110, 110, 110, 0.15);
  border-radius: 8px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .box {
    padding: 32px 32px;
    ul {
      justify-content: space-between;
      width: 65%;
      margin: auto;
      margin-bottom: 15%;

      .style_li {
        color: #666666;
        position: relative;
      }

      .style_li.is-active:hover {
        color: #333333;
      }

      .style_li.active:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -6px;
        // transform: translateX(-50%);
        width: 100%;
        height: 3px;
        background: #00924c;
        transition: all 0.5s;
      }

      .style_li.is-active:after {
        display: block;
      }
    }
    /deep/ .el-input__inner {
      height: 42px;
    }
    .fonT12 {
      font-size: 14px;
    }

    .justify {
      margin-bottom: 20px;
      /deep/ .el-checkbox__label {
        font-size: 14px;
      }
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }

    /deep/ .el-checkbox__label {
      font-size: 12px;
    }

    .sendcode {
      margin-left: -29px;
      font-size: 14px;
    }

    .w230 {
      width: 100%;
      height: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // margin-bottom: 10px;
    }

    /deep/ .el-button--primary {
      border: none;
      background: #00924c;
    }

    // loading
    /deep/ .el-button--primary {
      height: 42px;
      overflow: hidden;
      background: #00924c;

      /deep/ .el-loading-spinner i {
        color: #ffffff;
        margin-right: 10px;
        font-size: 24px !important;
      }

      .el-loading-spinner {
        background: #00924c;
        display: flex;
        height: 100%;
        line-height: 100%;
        justify-content: center;
        align-items: center;

        .el-loading-text {
          color: #ffffff;
        }
      }
    }
  }
  /deep/.el-input-group__append {
    color: #00924c;
    background: #f5f5f5;
    border: none;
  }
  .login-wrapper[data-v-26084dc2] .el-input .el-input-group__append {
    line-height: 50px !important;
  }

  /deep/ .el-input__inner {
    border: none;
    background: #f5f5f5;
  }
  /deep/ .el-input__inner::placeholder {
    color: #999999;

    font-family: Microsoft YaHei;
  }
  .login_title {
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #00924b;
    margin-bottom: 40px;
  }
  .lostpsa {
    color: #fc6c38;
  }
  .quick {
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    margin-top: 20px;
    padding: 0 15px;
    padding-top: 15px;
    border-top: 1px solid #ededed;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    a {
      color: #00924c;
      font-weight: bold;
    }
    .left_icon {
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }
      p {
        color: #666666;
        font-size: 14px;
      }
      p:hover {
        color: #00924b;
      }
    }

    a {
      font-weight: normal;
      .el-icon-d-arrow-right {
        font-size: 10px;
      }
    }
  }
  el-form-item:last-child{
    margin-bottom: 0;
  }
  .checked {
    color: #66716C;

    a {
      color: #00924b;
    }
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #66716C;
  }
}
</style>
